.gpt3__contact-form {
  font-family: var(--font-family);
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.gpt3__contact-form label {
  font-family: var(--font-family);
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #FF4820;
}

.gpt3__contact-form input,
.gpt3__contact-form textarea {
  font-family: var(--font-family) !important;
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 0px solid #ccc;
  border-radius: 4px;
}

.gpt3__contact-form button {
  background-color: #FF4820;
  color: #fff;
  padding: 12px 16px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.gpt3__contact-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.gpt3__contact-container {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.gpt3__contact-form {
  flex: 0.75;
  background-color: #000;
  padding: 2rem;
  border-radius: 20px;
}

.gpt3__contact-form p {
  color: #fff;
  font-size: 1.5rem;
}

.gpt3__contact-form h3 {
  color: #fff;
  font-size: 2.5rem;
  margin-top: 1rem;
}

.gpt3__contact-form form {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.gpt3__contact-form label {
  display: flex;
  flex-direction: column;
}

.gpt3__contact-form span {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.gpt3__contact-form input,
.gpt3__contact-form textarea {
  background-color: #c7c7c7;
  padding: 1rem;
  color: #000;
  border: none;
  border-radius: 8px;
  outline: none;
  font-family: var(--font-family);
  font-weight: bold;
}

.gpt3__contact-form button {
  background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  color: #FF4820;
  font-weight: bold;
  cursor: pointer;
}

.gpt3__possibility_contact-section {
  display: flex;
  flex-direction: row;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url(../../assets/Team_2.webp);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.gpt3__possibility-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 2rem;
}

.gpt3__possibility-image img {
  width: 100%;
  height: 100%;
}

.gpt3__form-container {
  width: 40%;
  margin: 0 auto;
}

.gpt3__possibility-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.gpt3__possibility-content h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71E5FF;
}

.gpt3__possibility-content a {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71E5FF;
}

.gpt3__possibility-content h4:last-child {
  color: #ff8A71;
}

.gpt3__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 28px;
  line-height: 45px;
  margin: 1rem 0;
}

.gpt3__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 2rem;
}

.gpt3__possibility-content_contact {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gpt3__possibility-content_contact h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71E5FF;
}

.gpt3__possibility-content_contact a {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71E5FF;
}

.gpt3__possibility-content_contact h4:last-child {
  color: #ff8A71;
}

.gpt3__possibility-content_contact h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 28px;
  line-height: 45px;
  margin: 1rem 0;
}

.gpt3__possibility-content_contact p {
  color: var(--color-text);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .gpt3__possibility {
    flex-direction: column;
  }

  .gpt3__possibility-image {
    margin: 1rem 0;
  }

  .gpt3__possibility-image img {
    width: unset;
    height: unset;
  }

  .gpt3__possibility-content {
    margin-top: 2rem;
  }

  .gpt3__form-container {
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 700px) {
  .gpt3__possibility-image img {
    width: 100%;
    height: 100%;
  }

  .gpt3__possibility-content_contact {
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 950px) {
  .gpt3__possibility_contact-section {
    flex-direction: column;
  }

  .gpt3__possibility-content_contact {
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 951px) {
  .gpt3__possibility_contact-section {
    flex-direction: row;
  }

  .gpt3__possibility-content_contact {
    margin-top: 0; /* Reset margin-top */
  }
}