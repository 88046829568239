@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

/* Container Styling */
.gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    padding: 20px;
    background-color: var(--color-black) !important; /* Light background for contrast */
    margin: 0 auto;
    max-width: 100% !important;
  }
  
  /* Each gallery item styling */
  .gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    background-color: var(--color-black) !important;
  }
  
  /* Fun hover effect to make the images pop */
  .gallery-item:hover {
    transform: scale(1.05);
  }
  
  /* Image Styling */
  .gallery-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Information overlay */
  .gallery-item-info {
    font-family: var(--font-family);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay with transparency */
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Hover effect for title */
  .gallery-item:hover .gallery-item-info {
    opacity: 1;
  }
  
  /* Gallery title */
  .gallery-item-info h3 {
    font-family: var(--font-family);
    margin: 0;
    font-size: 1.5rem;
    color: #fff;
    /* text-transform: uppercase; */
    font-weight: bold;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .gallery-container {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  
    .gallery-item-info h3 {
      font-size: 1.2rem;
    }
  }
  