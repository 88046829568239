/* projects.css */
.projects {
    position: relative;
    background: var(--color-primary) !important;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    font-family: var(--font-family);
}

.projects-header {
    height: 50vh;
}

.carousel-item {
    width: 100%;
    height: 50vh;
    background-color: #ddd;
}

.projects-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}

.project-card {
    width: 80%;
    margin-bottom: 20px;
    font-family: var(--font-family);
    border: 1px solid #81AFDD;
    background: -moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background: -o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background: -ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 700px) {
    .project-card {
        width: 95vw;
        margin-bottom: 2rem;
    }
}

.project-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    overflow: auto;
}

.modal-content {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9));
    color: var(--color-text);
    width: 90vw;
    /* max-width: 900px; */
    padding: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.video-container {
    width: 100%;
    height: 0;
    margin: auto;
    margin-top: 4rem;
    /* padding-bottom: 56.25%; */
    position: absolute;
    aspect-ratio: 16/9;
}

@media screen and (min-width: 700px) {
    .modal-content {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .video-container {
        width: 50%;
        padding-bottom: 0;
    }
}

@media screen and (max-width: 500px) {
    .modal-content {
        padding: 20px;
    }

    .video-container,
    .project-details {
        margin-bottom: 2px;
    }
}

iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.project-details {
    width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

@media screen and (min-width: 700px) {
    .project-details {
        width: 50%;
    }
}

.project-details p {
    color: var(--color-text);
    font-family: var(--font-family);
    margin-top: 1rem;
}

.gpt3__closemodal-btn {
    position: absolute;
    /* top: 20px;
    right: 20px; */
    margin-bottom: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.gpt3__closemodal-btn:hover {
    transform: scale(1.1);
}

/* .gpt3__closemodal-btn:before {
    content: "×";
    font-size: 20px;
    font-weight: bold;
    color: #fff;
} */

@media screen and (min-width: 700px) {
    .gpt3__closemodal-btn {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1rem !important;
    }
}

.gpt3__closemodal-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 90px;
}

@media screen and (max-width: 500px) {
    .modal-content {
        width: 95%;
        max-width: 100%;
        flex-direction: column;
    }

    .video-container,
    .project-details {
        width: 100%;
        padding-left: 0;
        margin-bottom: 20px;
    }

    .gpt3__closemodal-btn {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
}

.video-container {
    width: 100%;
    height: 100%;
    position: relative;
}

iframe {
    width: 100%;
    height: 100%;
}

.project-details {
    width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
}

@media screen and (min-width: 700px) {
    .project-details {
        width: 50%;
    }
}

.project-info {
    padding: 20px;
    margin-bottom: 1.5rem;
}

.project-info img {
    max-width: 100px;
    filter: brightness(0) invert(1);
}

.project-info h1 {
    font-size: 1.6rem;
    margin: 10px 0;
}

.project-info p {
    font-size: 1.1rem;
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 600;
}

.gpt3__Projects-content {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

@media screen and (min-width: 700px) {
    .gpt3__Projects-content {
        justify-content: center;
        /* Change this to justify-content: center; */
    }
}

.gpt3__Projects-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.gpt3__Projects-content h4:last-child {
    color: #ff8A71;
}

.gpt3__Projects-content h1 {
    font-family: var(--font-family);
    color: #fff;
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.gpt3__Projects-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .gpt3__possibility {
        flex-direction: column;
    }

    .gpt3__possibility-image {
        margin: 1rem 0;
    }

    .gpt3__possibility-image img {
        width: unset;
        height: unset;
    }

    .gpt3__Projects-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__possibility-image img {
        width: 100%;
        height: 100%;
    }
}

.video-frame {
    width: 100%;
    height: 400px;
}