/* ytvid.css */

.gpt3__cta_video {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: #517495;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect fill="%23002853" width="100%" height="100%"/></svg>') #002853;
    background: -webkit-radial-gradient(center, ellipse cover, rgba(81, 145, 165, 1) 0%, rgba(0, 40, 83, 1) 100%);
    background: radial-gradient(ellipse at center, rgba(81, 145, 165, 1) 0%, rgba(0, 40, 83, 1) 100%);
}

.gpt3__cta-video {
    width: 100%;
    max-width: 800px;
    margin: 2rem auto;
    /* Center the video */
    position: relative;
    overflow: hidden;
}

.gpt3__cta-video::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    /* 16:9 aspect ratio (height/width) */
}

.gpt3__cta-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    transition: transform 0.5s ease;
}

/* .gpt3__cta-video:hover iframe {
    transform: scale(1.1);
  } */