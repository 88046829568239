/* brand.css */
.gpt3__brand {
  overflow: hidden;
  background: var(--color-footer);
  padding: 1rem;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  max-width: 80%;
  height: auto;
  max-height: 8rem;
  filter: brightness(0) invert(1);
}

@media (max-width: 768px) {
  .logo-container img {
    width: 70%;
    margin-top: 2rem;
  }
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .logo-slider {
    animation: none;
  }
}

.gpt3__brand-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
  margin-bottom: 4%;

  margin-left: 2rem;
}

.gpt3__brand-btn button {
  background: #000;
  color: #fff;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border-radius: 2rem;

  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
}

@media screen and (max-width: 650px) {
  .gpt3__cta {
    flex-direction: column;
  }

  .gpt3__brand-btn {
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 550px) {
  .gpt3__cta {
    flex-direction: column;
    margin: 4rem 2rem;
  }

  .gpt3__cta-content h3 {
    font-size: 18px;
    line-height: 32px;
  }

  .gpt3__brand-btn button {
    font-size: 14px;
    line-height: 28px;
  }
}